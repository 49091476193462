import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { callAPI } from "../../utils/apiUtils";
import { apiUrls } from "../../utils/apiUrls";
import { defaultConfig, Usertype } from "../../config";

const TrackActivity = () => {
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem("userData"));
 

  const getIPAddress = async () => {
    try {
      const response = await fetch("https://api64.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP:", error);
      return "Unknown IP"; // Fallback value
    }
  };

  const postApi = async () => {
    try {
      const ip = await getIPAddress();
      const Data = {
        url: defaultConfig.tractActivity + location.pathname,
        userId:userData?._id?userData?._id:"",
        ip: userData?._id?"":ip,
      };
      const response = await callAPI(apiUrls.userActivity, {}, "POST", Data);
      if (!response.data.status) {
        console.log(response.data.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    if(userData?.userType !==Usertype.restaurant){
      postApi();
    }
   
    // eslint-disable-next-line
  }, [location.pathname]);
};

export default TrackActivity;
