export const apiUrls = {
    //Auth
    login:"login",
    verifyOtp:"otpVerify",
    uploadImageArr:"uploadImageArr",
    activerestaurant:"activerestaurant",
    restauantdetiels:"restauantDetail",
    profileData:"userData",
    uploadOcr:"uploadOcr",
    addRestaurantReview:"addRestaurantReview",
    branchLogin:"restaurantProfileData",
    mediaGetAll:"mediaGetAll",
    //category
    categoryList:"listFoodCategoryForUser",
    addCategory:"addFoodCategoryByUser",
    //menu
    addRestaurantMenu:"addRestaurantMenu",
    editRestaurantMenu:"updateRestaurantMenu",
    deleteRestaurantMenu:"deleteRestaurantMenu",
    getMenuDetails:"viewRestaurantMenu",
    menuList:"listRestaurantMenu",
    restaurantViewMenulist:"listingRestaurantMenu",
    commonPairingList:"listingMenuPairing",
    reviewRestaurantListing:"reviewRestaurantListing",
    approveRejectRestaurantReview:"approveRejectRestaurantReview",
    leaderBoardMenu:"leaderBoardOfMenu",
    totalRestaurantLike:"totalRestaurantLike",
    leaderBoardOfUniqueMenu:"leaderBoardOfUniqueMenu",
    userRestaurantLeader:"userRestaurantLeader",
    getBadge:"getBadge",
    //invite
    inviteAfriend:"userinvite",
    inviteRestaurant:"inviteRestaurant",
    userReviewListing:"userDataByWeek",
    //logoclick
    storeRestaurantOrderHistory:"orderPlatform",
    //feedback
    addFeedback:"addFeedback",
    platformFeedback:"addplatformFeedback",
    userReviewLeaderBoardListing:"userReviewLeaderBoardListing",
    //restaurant Menu horixonatl scrollbar
    topMostRestaurantMenu:"topmostRestaurantMenu",
    //tags
    tagList:"tagList",
    userRewardsPoints:"UserReviewPoints",
    //user History 
    userOrderHistory:"userOrderHistory",
    CreateTags:"createTags",
    menuTagsSuggestion:"menuTagsSuggestion",
    cities:"cities",
    //user loyality points
    getLoyalityPoints:"getLoyalityPoints",
    redeemUserPoints:"redeemUserPoints",
    redeemHistory:"redeemHistory",
    //user meanu redeem history
    userRedeemHistory:"userRedeemHistory",
    //menuPoints
    getmenuPoints:"getmenuPoints",
    tagcounts:"tagcounts",
    userActivity:"userActivity",
};
